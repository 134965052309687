export default {
	page_title: 'Active season',
	statistics: 'Statistics',
	battle_pass: 'Season tasks',
	gifts: 'My prizes',
	'values.title.position': '#',
	'values.title.total': 'Total points',
	'values.title.watching': 'For watching',
	'values.title.activities': 'For activities',
	'battle_pass.provider.self': ' ',
	'battle_pass.provider.twitch': 'Twitch',
	'battle_pass.provider.telegram': 'Telegram',
	'battle_pass.provider.youtube': 'YouTube',
	'battle_pass.provider.winline': 'Winline',
	'battle_pass.condition.join': 'Connect {provider}',
	'battle_pass.condition.subscribe': 'Subscribe to {provider} channel',
	'battle_pass.condition.goal': 'Get {number} points',
	'battle_pass.condition.tags': 'Complete special task',
	'battle_pass.action.join': 'Connect',
	'battle_pass.action.joined': 'Connected',
	'battle_pass.action.complete': 'Complete',
	'battle_pass.action.completed': 'Completed',
	'battle_pass.action.subscribe': 'Subscribe',
	'battle_pass.action.subscribed': 'Subscribed',
	'battle_pass.action.subscribe.check': 'Subscribed, check it please',
	'battle_pass.action.check': 'I match all conditions, check it please',
	'battle_pass.waiting': 'Waiting for check',
	'battle_pass.success': 'Cheeck complete',
};
